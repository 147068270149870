<template>
  <b-navbar fixed="top" :sticky="true" type="dark" variant="dark">
    <b-navbar-brand :disabled="isDisabled" href="#">WeShite</b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item :disabled="isDisabled" href="#">Home</b-nav-item>
        <b-nav-item :disabled="isDisabled" href="#">News</b-nav-item>
        <b-nav-item :disabled="isDisabled" href="#">About</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    isDisabled: {
      Type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>