<template>
  <div>
    <div class="main-div"></div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
}
</script>

<style scoped>
.main-div {
  text-align: center;
  margin-top: 13px;
}
</style>