<template>
  <div id="app">
    <div class="maxheight" v-if="isUnderConstruction">
      <NavBar :isDisabled="true" />
      <ConstructionPage />
    </div>
    <div class="maxheight" v-else>
      <NavBar :isDisabled="false" />
      <router-view />
    </div>
  </div>
</template>

<script>
require("./main.scss")

import NavBar from "./components/NavBar.vue"
import ConstructionPage from "./views/ConstructionPage.vue"
export default {
  name: "App",
  components: {
    NavBar,
    ConstructionPage,
  },
  data() {
    return {
      isUnderConstruction: true,
    }
  },
  created() {
    document.title = "WeShite"
  },
}
</script>