<template>
  <div class="background-4 construction">
    <div class="text-background">
      <h1 class="p-3">
        Sorry! <br />
        But this page is currently under construction!
      </h1>
      <h3 class="p-3">Please visit us again at a later date.</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConstructionPage",
  data() {
    return {}
  },
}
</script>

<style scoped>
.construction {
  text-align: center;
  position: relative;
  color: white;
}
</style>